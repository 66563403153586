import React from 'react';
import { Layout, Col } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import screenshotFeature from '../assets/screenshot_feature.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function Features() {
  return (
    <Content>
      <Row className={styles.featuresRow} id="feature1">
        <Col span={12}>
          <h2>Put a cool title here to illustrate</h2>
          <span>
            Explain what our cool feature does and put a nice screenshot of it
            on the right
          </span>
        </Col>
        <Col span={12}>
          <img
            className={styles.imgPresentation}
            src={screenshotFeature}
            alt={'screenshotFeature'}
          />
        </Col>
      </Row>
      <Row className={styles.featuresRow} id="feature2">
        <Col span={12}>
          <img
            className={styles.imgPresentation}
            src={screenshotFeature}
            alt={'screenshotFeature'}
          />
        </Col>
        <Col span={12}>
          <h2>Put a cool title here to illustrate</h2>
          <span>
            Explain what our cool feature does and put a nice screenshot of it
            on the left
          </span>
        </Col>
      </Row>
      <Row className={styles.featuresRow} id="feature3">
        <Col span={12}>
          <h2>Put a cool title here to illustrate</h2>
          <span>
            Explain what our cool feature does and put a nice screenshot of it
            on the right
          </span>
        </Col>
        <Col span={12}>
          <img
            className={styles.imgPresentation}
            src={screenshotFeature}
            alt={'screenshotFeature'}
          />
        </Col>
      </Row>
      <Row className={styles.featuresRow} id="feature4">
        <Col span={12}>
          <img
            className={styles.imgPresentation}
            src={screenshotFeature}
            alt={'screenshotFeature'}
          />
        </Col>
        <Col span={12}>
          <h2>Put a cool title here to illustrate</h2>
          <span>
            Explain what our cool feature does and put a nice screenshot of it
            on the left
          </span>
        </Col>
      </Row>
    </Content>
  );
}
