import React from 'react';
import { Layout } from 'antd';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Cta from '../components/Cta';
import Cut from '../components/Cut';
import Newsletter from '../components/Newsletter';
import Nav from '../components/Nav';
import FeaturesView from '../components/Features';

export default function Features() {
  return (
    <Layout>
      <Header />
      <>
        <Cut />
        <FeaturesView />
        <Cta />
        <Newsletter />
        <Nav />
      </>
      <Footer />
    </Layout>
  );
}
